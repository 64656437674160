import React, { useState, useEffect, useRef } from "react";
import Modal from "../Modal";
import Overlay from "../Overlay";
import { useFormContext } from "react-hook-form";
import "../../styles/imageUploadStyles.css";
import { v4 as uuidv4 } from "uuid";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { toast } from "react-toastify";
import { v4 as uuid4 } from "uuid";
import Compressor from "compressorjs";
import { useTranslation } from "react-i18next";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence } from "framer-motion";
import { useDrag, useDrop } from "react-dnd";

const ImageUpload = ({
  clearErrors,
  selectedfile,
  setSelectedFile,
  setShowLoader,
  duplicate = false,
}) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const dropZoneRef = useRef(null);

  const {
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(["auctionForm", "errors"]);
  const [imgDelete, setImgDelete] = useState(null);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const storage = getStorage();
  const duplicateImagesCalled = useRef(false);

  const moveImage = (dragIndex, hoverIndex) => {
    const dragImage = selectedfile[dragIndex];
    setSelectedFile((prevState) => {
      const newState = [...prevState];
      newState.splice(dragIndex, 1);
      newState.splice(hoverIndex, 0, dragImage);
      return newState;
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: "IMAGE",
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = calculateHoverIndex(monitor, dragIndex);
      moveImage(dragIndex, hoverIndex);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const calculateHoverIndex = (monitor, dragIndex) => {
    const hoverBoundingRect = monitor.getClientOffset();
    const dropTargetRects = Array.from(
      document.querySelectorAll(".file-atc-box")
    ).map((el) => el.getBoundingClientRect());

    const draggedItemMiddleX =
      (dropTargetRects[dragIndex].left + dropTargetRects[dragIndex].right) / 2;
    const draggedItemMiddleY =
      (dropTargetRects[dragIndex].top + dropTargetRects[dragIndex].bottom) / 2;

    const closestRect = dropTargetRects.reduce(
      (prevClosest, rect, index) => {
        const distanceX = Math.abs(hoverBoundingRect.x - rect.left);
        const distanceY = Math.abs(hoverBoundingRect.y - rect.top);
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

        if (distance < prevClosest.distance) {
          return { index, distance };
        }
        return prevClosest;
      },
      { index: dragIndex, distance: Infinity }
    );

    if (closestRect.index === dragIndex) {
      return dragIndex;
    }
    return closestRect.index;
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const inputChange = async (e) => {
    const maxImages = 24;
    const selectedFileCount = selectedfile.length;
    const remainingImageSlots = maxImages - selectedFileCount;

    for (let i = 0; i < e.target.files.length && i < remainingImageSlots; i++) {
      const file = e.target.files[i];

      if (file.type.startsWith("image/")) {
        setShowLoader(true);
        new Compressor(file, {
          quality: 0.2,
          success: async (compressedFile) => {
            const uniqueFileName = `${uuidv4()}_${compressedFile.name}`;
            const storageRef = ref(storage, `images/${uniqueFileName}`);
            const snapshot = await uploadBytes(storageRef, compressedFile);
            const downloadURL = await getDownloadURL(snapshot.ref);
            setShowLoader(false);
            setSelectedFile((preValue) => {
              return [
                ...preValue,
                {
                  id: uuid4(),
                  filename: uniqueFileName,
                  filetype: compressedFile?.type,
                  fileimage: downloadURL,
                  datetime:
                    compressedFile?.lastModifiedDate?.toLocaleString("en-IN"),
                  filesize: filesizes(compressedFile?.size),
                  bucket: "images",
                },
              ];
            });
          },
        });
      } else {
        toast.error(`File ${file.name} is not an image and won't be uploaded.`);
      }
    }

    if (e.target.files.length > remainingImageSlots) {
      toast.error(`You can upload a maximum of ${maxImages} images.`);
    }
  };

  const duplicateImages = async (imagesToCopy, destinationBucket) => {
    try {
      setShowLoader(true);
      const storage = getStorage();
      const copiedImages = [];
      const copiedFileNames = new Set();

      for (const image of imagesToCopy) {
        const originalRef = ref(storage, `${image.bucket}/${image.filename}`);
        const originalImageUrl = await getDownloadURL(originalRef);

        // Download the original image
        const response = await fetch(originalImageUrl);
        const blob = await response.blob();

        let uniqueFileName;

        // If the file name has already been copied, use the original file name
        if (copiedFileNames.has(image.filename)) {
          uniqueFileName = image.filename;
        } else {
          // Create a new unique file name
          uniqueFileName = `${uuidv4()}_${image.filename}`;
          copiedFileNames.add(image.filename);
        }

        const copiedRef = ref(
          storage,
          `${destinationBucket}/${uniqueFileName}`
        );

        // Upload the downloaded image to the new location
        await uploadBytes(copiedRef, blob);
        const copiedImageUrl = await getDownloadURL(copiedRef);

        copiedImages.push({
          id: uuidv4(),
          filename: uniqueFileName,
          filetype: image.filetype,
          fileimage: copiedImageUrl,
          datetime: new Date().toLocaleString("en-IN"),
          filesize: filesizes(blob.size),
          bucket: destinationBucket,
        });
      }

      setSelectedFile(copiedImages);
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error("Unable to copy images right now. Please try again" + error);
      setSelectedFile([]);
    }
  };

  useEffect(() => {
    if (duplicate && !duplicateImagesCalled.current) {
      duplicateImages(selectedfile, "duplicatedImages");
      duplicateImagesCalled.current = true;
    }
  }, [duplicate]);

  // useEffect(() => {
  //   setValue("images", selectedfile);
  // }, [selectedfile]);
  useEffect(() => {
    setValue("images", selectedfile);

    if (selectedfile.length > 24) {
      setError("images", {
        type: "manual",
        message: "You can select a maximum of 24 images",
        ref: { name: "images" },
      });
      toast.error("You can select a maximum of 24 images");
    } else if (!selectedfile.length) {
      setError("images", {
        type: "required",
        message: "Please select at least 1 image",
        ref: { name: "images" },
      });
      // toast.error("Please select at least 1 image");
    } else {
      clearErrors("images");
    }
  }, [selectedfile, setError, clearErrors, setValue]);

  const deleteSelectFile = async (id, filename, destination) => {
    try {
      setShowLoader(true);
      const result = selectedfile.filter((data) => data.id !== id);
      const imgRef = ref(storage, `${destination}/${filename}`);

      await deleteObject(imgRef);
      setSelectedFile(result);
      setDeleteModal(false);
      setShowLoader(false);
      setValue(
        "images",
        result.map((image) => image.fileimage)
      );
      toast.success("Image Deleted Successfully");
    } catch (error) {
      toast.success("Image Deleted Successfully");
      const result = selectedfile.filter((data) => data.id !== id);
      setSelectedFile(result);
      setDeleteModal(false);
      setShowLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedfile.length > 24) {
      setError("images", {
        type: "manual",
        message: "You can select a maximum of 24 images",
        ref: {
          name: "images",
        },
      });
    } else if (!selectedfile.length) {
      setError("images", {
        type: "required",
        message: "Please select at least 1 image",
        ref: {
          name: "images",
        },
      });
    } else {
      clearErrors("images");
    }
  }, [selectedfile, setError, clearErrors]);

  const moveImgUp = (index) => {
    if (index > 0) {
      setSelectedFile((prevState) => {
        const newState = [...prevState];
        [newState[index], newState[index - 1]] = [
          newState[index - 1],
          newState[index],
        ];
        return newState;
      });
    }
  };

  const moveImgDown = (index) => {
    if (index < selectedfile.length - 1) {
      setSelectedFile((prevState) => {
        const newState = [...prevState];
        [newState[index], newState[index + 1]] = [
          newState[index + 1],
          newState[index],
        ];
        return newState;
      });
    }
  };

  useEffect(() => {
    const dropZone = dropZoneRef.current;

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      const files = Array.from(e.dataTransfer.files);
      handleFileUpload(files);
    };

    if (dropZone) {
      dropZone.addEventListener("dragover", handleDragOver);
      dropZone.addEventListener("drop", handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener("dragover", handleDragOver);
        dropZone.removeEventListener("drop", handleDrop);
      }
    };
  }, []);

  const handleFileUpload = async (files) => {
    const maxImages = 24;
    const selectedFileCount = selectedfile.length;
    const remainingImageSlots = maxImages - selectedFileCount;

    for (let i = 0; i < files.length && i < remainingImageSlots; i++) {
      const file = files[i];

      if (file.type.startsWith("image/")) {
        setShowLoader(true);
        new Compressor(file, {
          quality: 0.2,
          success: async (compressedFile) => {
            const uniqueFileName = `${uuidv4()}_${compressedFile.name}`;
            const storageRef = ref(storage, `images/${uniqueFileName}`);
            const snapshot = await uploadBytes(storageRef, compressedFile);
            const downloadURL = await getDownloadURL(snapshot.ref);
            setShowLoader(false);
            setSelectedFile((preValue) => {
              return [
                ...preValue,
                {
                  id: uuid4(),
                  filename: uniqueFileName,
                  filetype: compressedFile.type,
                  fileimage: downloadURL,
                  datetime:
                    compressedFile.lastModifiedDate.toLocaleString("en-IN"),
                  filesize: filesizes(compressedFile.size),
                },
              ];
            });
          },
        });
      } else {
        toast.error(`File ${file.name} is not an image and won't be uploaded.`);
      }
    }

    if (files.length > remainingImageSlots) {
      toast.error(`You can upload a maximum of ${maxImages} images.`);
    }
  };
  // const dropZone = dropZoneRef.current;
  // console.log(dropZone);

  return (
    <>
      <div className="fileupload-view" ref={dropZoneRef}>
        <div className="img-card">
          <div className="card-body">
            <div className="kb-data-box">
              <div className="kb-modal-data-title">
                <div className="kb-data-title">
                  <h6>{t("imgHeading")}</h6>
                  <h6>Total Selected Images {selectedfile.length}</h6>
                </div>
              </div>
              <div className="kb-file-upload">
                <div className="file-upload-box">
                  <input
                    type="file"
                    id="fileupload"
                    className="file-upload-input"
                    onChange={inputChange}
                    multiple
                    accept="image/*"
                  />
                  <span>
                    {t("dnd")}
                    <span className="file-link">{t("chooseFile")}</span>
                  </span>
                </div>
              </div>
              <div
                ref={drop}
                className="kb-attach-box mb-3"
                // style={{ backgroundColor: isOver ? "lightgray" : "inherit" }}
              >
                {selectedfile.map((data, index) => (
                  <Image
                    key={index}
                    data={data}
                    index={index}
                    t={t}
                    setDeleteModal={setDeleteModal}
                    setImgDelete={setImgDelete}
                    moveImgDown={moveImgDown}
                    moveImgUp={moveImgUp}
                  />
                ))}
              </div>
            </div>
            {errors.images && (
              <span className="inputErrors">
                {t(errors.images.message, { ns: "errors" })}
              </span>
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showDeleteModal && (
          <>
            <Overlay onClose={() => setDeleteModal(false)} />
            <Modal
              onCancel={() => setDeleteModal(false)}
              onAgree={() => {
                deleteSelectFile(
                  imgDelete.id,
                  imgDelete.filename,
                  imgDelete.bucket
                );
              }}
              cancelText={"Cancel"}
              successText={"Delete"}
              icon={faTrash}
            >
              <h2>Delete</h2>
              <p>Are you sure you want to delete {imgDelete.filename}?</p>
            </Modal>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default ImageUpload;

const Image = ({
  data,
  index,
  t,
  setDeleteModal,
  setImgDelete,
  moveImgDown,
  moveImgUp,
}) => {
  const { id, filename, filetype, fileimage, datetime, filesize } = data;

  const [{ isDragging }, drag] = useDrag({
    type: "IMAGE",
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging,
    }),
  });

  return (
    <div
      ref={drag}
      // style={{ opacity: isDragging ? 0.5 : 1 }}
      className="file-atc-box"
      key={id}
    >
      {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
        <div className="file-image">
          <img src={fileimage} alt="" />
        </div>
      ) : (
        <div className="file-image">
          <i className="far fa-file-alt"></i>
        </div>
      )}
      <div className="file-detail">
        {index === 0 ? (
          <p>
            <span style={{ fontWeight: "bold" }}>{t("thumb")}</span>
          </p>
        ) : (
          ""
        )}
        <h6>{filename}</h6>
        <p></p>
        <p>
          <span>Size : {filesize}</span>
          <span className="ml-2">Modified Time : {datetime}</span>
        </p>
        <div className="file-actions">
          <button
            type="button"
            className="file-action-btn"
            onClick={() => {
              setDeleteModal(true);
              setImgDelete(data);
            }}
          >
            {t("deleteImg")}
          </button>
          <button
            type="button"
            className="file-action-btn"
            onClick={() => {
              moveImgUp(index);
            }}
          >
            Move Up
          </button>
          <button
            type="button"
            className="file-action-btn"
            onClick={() => {
              moveImgDown(index);
            }}
          >
            Move Down
          </button>
        </div>
      </div>
    </div>
  );
};
