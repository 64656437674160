import { useState, useEffect } from "react";
import { auth, db } from "../../config/firebaseSetup";
import {
  collection,
  query,
  where,
  writeBatch,
  getDocs,
  doc,
} from "firebase/firestore";

import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const SUPPORT_OPTIONS = [
  { id: "auctions", label: "Auctions Help" },
  { id: "payment", label: "Payment Support" },
  { id: "others", label: "Others" },
];

const NewConversation = ({ onNewChat }) => {
  const [newConversationOptions, setNewConversationOptions] = useState(false);
  const [availableAdmins, setAvailableAdmins] = useState([]);
  // const [previousConversations, setPreviousConversations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAdmins = async () => {
      const q = query(collection(db, "admins"));
      const querySnapShot = await getDocs(q);
      setAvailableAdmins(
        querySnapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    fetchAdmins();
  }, []);

  useEffect(() => {
    // fetchPreviousConversations();
  }, []);

  // const fetchPreviousConversations = async () => {
  //   if (!auth.currentUser) return;

  //   try {
  //     const q = query(
  //       collection(db, "conversations"),
  //       where("participants", "array-contains", auth.currentUser.email)
  //     );

  //     const querySnapshot = await getDocs(q);
  //     const conversations = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     setPreviousConversations(conversations);
  //   } catch (error) {
  //     console.error("Error fetching previous conversations:", error);
  //   }
  // };

  const assignRandomAdmin = (admins) => {
    const randomIndex = Math.floor(Math.random() * admins.length);
    return admins[randomIndex];
  };

  const handleStartConversation = async (selectedOption) => {
    setLoading(true);
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error("No authenticated user found.");
        return;
      }

      const batch = writeBatch(db);

      // Create a new conversation
      const newConversationRef = doc(collection(db, "conversations"));
      const randomAdmin = assignRandomAdmin(availableAdmins);
      const assignedAdminId = randomAdmin.id;
      const assignedAdminEmail = randomAdmin.email;

      const conversationData = {
        participants: [currentUser.email, currentUser.uid],
        assignedAdmins: [assignedAdminId],
        lastUpdated: Date.now(),
      };

      batch.set(newConversationRef, conversationData);

      const emailSubject = `New Support Request: ${selectedOption}`;
      const emailBody = `
  Dear Support Team Member,

  A new support request has been assigned to you:

  Category: ${selectedOption}
  User: ${currentUser.email}
  Time: ${new Date().toLocaleString()}
  Conversation ID: ${newConversationRef.id}

  Please log in to the admin dashboard to respond to this request.

  Best regards,
  Support System
  `;

      // Add user's selected option as first message
      const userMessageRef = doc(collection(newConversationRef, "messages"));
      const userMessageData = {
        senderId: currentUser.uid,
        message: selectedOption,
        timestamp: Date.now(),
        read: false,
      };
      batch.set(userMessageRef, userMessageData);

      // Add automatic response
      const autoMessageRef = doc(collection(newConversationRef, "messages"));
      const autoMessageData = {
        senderId: "system",
        message:
          "Thank you for your message. An admin will get back to you shortly. For faster resolution, please tell us how we can help you today.",
        timestamp: Date.now() + 1,
        read: false,
      };
      batch.set(autoMessageRef, autoMessageData);

      // Update the mostRecentMessage field
      batch.update(newConversationRef, {
        mostRecentMessage: autoMessageData,
      });

      await batch.commit();

      // Pass the new conversation to onNewChat
      onNewChat({
        id: newConversationRef.id,
        ...conversationData,
        mostRecentMessage: autoMessageData,
      });

      await axios.post(
        "https://stripe-server-production-3c2c.up.railway.app/send-email",
        {
          emailBody,
          emailTo: assignedAdminEmail,
          emailSubject,
        }
      );
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    } finally {
      setLoading(false);
      setNewConversationOptions(false);
    }
  };

  const selectPreviousConversation = (conversation) => {
    onNewChat(conversation);
    setNewConversationOptions(false);
  };

  return (
    <div className="new-conversation-container">
      <Button
        className="newChatButton"
        onClick={() => setNewConversationOptions((prev) => !prev)}
      >
        <FontAwesomeIcon icon={faAdd} />
      </Button>

      {newConversationOptions && (
        <div className="conversation-options-mm">
          <h3>New conversation. Quick Select</h3>
          <div className="new-conversation-buttons-mm">
            {SUPPORT_OPTIONS.map((option) => (
              <button
                key={option.id}
                onClick={() => handleStartConversation(option.label)}
                disabled={loading}
                className="support-option-button"
              >
                {loading ? "Starting..." : option.label}
              </button>
            ))}
          </div>

          {/* {previousConversations.length > 0 && (
            <div className="previous-conversations">
              <h3>Continue Previous Conversation</h3>
              {previousConversations.map((conv) => (
                <div
                  key={conv.id}
                  className="conversation-item"
                  onClick={() => selectPreviousConversation(conv)}
                >
                  <p className="conversation-preview">
                    {conv.mostRecentMessage?.message || "Previous conversation"}
                  </p>
                  <span className="conversation-date">
                    {new Date(conv.lastUpdated).toLocaleDateString()}
                  </span>
                </div>
              ))}
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default NewConversation;
