import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Countdown from "../components/CountDown";
import Button from "../components/Button";
import AuthBtn from "../components/AuthBtn";
import "../styles/auctionItemDetailsStyles.css";
import BidNowModal from "../components/BidNowModal";
import Loading from "../components/Loader";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../components/Modal";
import { Context as AuctionContext } from "../context/AuctionContext";
import Slider from "../components/slider/Slider";

import {
  faContactCard,
  faExclamationCircle,
  // faEuro,
  faExclamationTriangle,
  faHeart,
  faHeartCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import iconEuro from "../assets/icons/euro.svg";
import {
  Timestamp,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../config/firebaseSetup";
import { toast } from "react-toastify";
import BidsModal from "../components/BidsModal";
import Overlay from "../components/Overlay";
import { useUser } from "../context/UserContext";
import ReportModal from "../components/ReportBidModal";
import ReportAuctionForm from "../components/ReportAuctionForm";
import { useTranslation } from "react-i18next";
import leftArrow from "../assets/icons/leftArrow.svg";
import Carousel from "../components/Carousel";
import { AnimatePresence, motion } from "framer-motion";
import { getMissingFields, hasCompletedInformation } from "../utils/checkuser";
import EditAuctionRequestForm from "../components/userAuctionsDashboard/EditAuctionRequestForm";
import euro from "../assets/icons/euro.svg";
import axios from "axios";
import fetchAndSendEmailTemplate from "../utils/emailSend";
import i18n from "../i18n/i18n";
import ReservePrice from "../components/ReservePrice";
import TagsColors from "../components/TagsColors";
import { onAuthStateChanged } from "firebase/auth";

const AuctionItemDetailPage = () => {
  // const { getAllAuctions } = useContext(Context);
  const [showBidNow, setBidNow] = useState(false);
  // const [quickBidValue, setQuickBidValue] = useState(0);
  const [userLiked, setUserLiked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showBidsModal, setShowBidModal] = useState(false);
  const [showReportModal, setReportModal] = useState(false);
  const [showContactModal, setContactModal] = useState(false);
  const [showBidReportModal, setShowBidReportModal] = useState(false);
  const [showBidContactModal, setShowBidContactModal] = useState(false);
  const [optionalBidAmount, setOptionalBidAmount] = useState(0);
  const [bidAmount, setBidAmount] = useState(0);
  const scrollIntoView = useRef();
  const prevAuctionItemRef = useRef(null);
  const location = useLocation();
  const { showLoginModalHandler } = useUser();
  // const { userData, getUserData, showLoginModalHandler } = useUser();
  const user = auth.currentUser;
  const [auctionStarted, setAuctionStarted] = useState(false);
  const [startDatePassed, setStartDatePassed] = useState(false);
  // const [userFieldsCompleted, setUserFieldsCompleted] = useState(null);
  const [showCompleteInfoModal, setShowCompleteInfoModal] = useState(false);
  // const [userProfileMissingFields, setUserProfileMissingFields] = useState([]);
  const timerRef = useRef(null);
  const [userData, setUserData] = useState(null);

  const methods = useForm();

  // const [auctionI, setAuctionItem] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [auctionEnded, setAuctionEnded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [auctionItem, setAuctionItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userLanguage, setUserLanguage] = useState("");
  const { urlId } = useParams();
  const id = decodeURIComponent(urlId);

  const navigate = useNavigate();
  const { t } = useTranslation("auctionItem");

  useEffect(() => {
    const detectedLanguage = navigator.language.split("-")[0];
    if (detectedLanguage === "it") {
      setUserLanguage("it");
    } else {
      setUserLanguage("en");
    }
  }, []);

  useEffect(() => {
    const userIsLoggedIn = user;
    setIsLoggedIn(userIsLoggedIn);
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
        } else {
          setUserData({});
        }
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // const auctionItem = auctions.find(
  //   (auction) => auction?.id === id && auction.status === "approved"
  // );

  // useEffect(() => {
  //   if (auctionItem) {
  //     getUserData();
  //   }
  // }, [auctionItem]);

  useEffect(() => {
    if (showBidNow) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showBidNow]);

  useEffect(() => {
    // Get auction from backend
    const fetchAuctionDetails = async () => {
      const auctionRef = doc(db, "Auction", id);
      const auctionDoc = await getDoc(auctionRef);
      const currentBid =
        Number(auctionDoc.data().highestBid) + 1 ||
        Number(auctionDoc.data()?.auctionStartingPrice);
      setBidAmount(quickBids ? quickBids[0] : 0);

      if (user) {
        // Check if bid history exists
        const bidHistory = auctionDoc.data().bidHistory || [];
        const userBid = bidHistory.find((bid) => bid.userId === user.uid);

        if (userBid) {
          setOptionalBidAmount(userBid.optionalBidAmount);
        }
      }
    };

    if (auctionItem) {
      fetchAuctionDetails();
    }
  }, [auctionItem, id]);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.preventDefault();
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const processBids = async (
    bidA,
    userId,
    userEmail,
    nationality,
    username,
    isAutoBid,
    optionalAmount = 0
  ) => {
    // This function is used to process the place bid from user
    try {
      const auctionRef = doc(db, "Auction", id);
      const auctionDoc = await getDoc(auctionRef);
      const auctionData = auctionDoc.data();

      // Fetch existing bid history
      const existingBidHistory = auctionDoc.data().bidHistory || [];

      // Check if the previous highest bidder exists
      if (auctionData.lastBidderId && auctionData.lastBidderId !== userId) {
        // Add the auction ID to the previous highest bidder's outbids array
        await addOutBidAuctionToUser(auctionData.lastBidderId, id);
      }

      // continue the bid process
      const updatedBidHistory = existingBidHistory.map((bid) => {
        if (bid.userId === userId) {
          return {
            ...bid,
            optionalBidAmount: Number.parseInt(optionalAmount),
          };
        }
        return bid;
      });

      // Add the new bid
      const timestamp = new Date().toISOString();
      const newBid = {
        userId: userId,
        bidAmount: Number.parseInt(bidA),
        optionalBidAmount: Number.parseInt(optionalAmount),
        timestamp: timestamp,
        userEmail: userEmail,
        nationality: nationality,
        username: username,
        userCommission: userData.commissionPercent,
      };

      updatedBidHistory.push(newBid);
      updatedBidHistory.sort((a, b) => b.bidAmount - a.bidAmount);

      // Outbid the current user if they were previously outbid on this auction
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      const userDataDoc = userDoc.data();

      if (userDataDoc?.outBids?.includes(id)) {
        const updatedOutBids = userDataDoc.outBids.filter(
          (auction) => auction !== id
        );
        await updateDoc(userDocRef, { outBids: updatedOutBids });
      }

      await updateUserProfileWithBid(id);
      await updateAuctionData(
        auctionRef,
        bidA,
        updatedBidHistory,
        userEmail,
        userId
      );

      // Check if the new bid was placed within the last 4 minutes before the scheduled end time
      const newBidTimestamp = new Date().getTime();
      const fourMinutesInMilliseconds = 4 * 60 * 1000;
      const auctionEndTime = auctionItem.serverEndTimeForAuction.toMillis();

      if (newBidTimestamp >= auctionEndTime - fourMinutesInMilliseconds) {
        // Extend the auction end time by 4 minutes
        const newEndTime = new Date(auctionEndTime + fourMinutesInMilliseconds);
        await updateAuctionEndTime(auctionRef, newEndTime);
      }

      !isAutoBid && toast.success("Bid placed successfully!");
      setShowLoader(false);
      setBidNow(false);

      const metaData = {
        auctionId: id,
        auctionTitleEn: auctionData.auctionTitle,
        auctionTitleIt: auctionData.auctionTitleItalian,
      };

      await checkBidAndSendNotification(
        user,
        bidAmount,
        updatedBidHistory,
        auctionDoc.data().lastBidderEmail,
        auctionDoc.data().lastBidderId,
        metaData,
        auctionData?.images[0]?.fileimage
      );

      setTimeout(async () => {
        // console.log(auctionData);
        await autoBidForUsers(updatedBidHistory, Number.parseInt(bidA), userId);
      }, 10000);
    } catch (error) {
      setShowLoader(false);
      setBidNow(false);
      console.log(error);
      !isAutoBid && toast.error("An error occurred while processing the bid");
    }
  };

  const updateAuctionEndTime = async (auctionRef, newEndTime) => {
    try {
      await updateDoc(auctionRef, {
        serverEndTimeForAuction: Timestamp.fromDate(newEndTime),
        isTimeExtended: true,
      });
    } catch (error) {
      console.error("Error updating auction end time:", error);
    }
  };

  const updateAuctionData = async (
    auctionRef,
    bidA,
    updatedBidHistory,
    email,
    uid
  ) => {
    let isReservePriceMet = false;
    if (bidA > auctionItem.auctionReservePrice) {
      isReservePriceMet = true;
    }
    // console.log({
    //   highestBid: Number.parseInt(bidA),
    //   lastBidderEmail: email,
    //   lastBidderId: uid,
    //   bidHistory: updatedBidHistory,
    //   isReservePriceMet: isReservePriceMet,
    // });
    // Updated auction in backend
    await updateDoc(auctionRef, {
      highestBid: Number.parseInt(bidA),
      lastBidderEmail: email,
      lastBidderId: uid,
      bidHistory: updatedBidHistory,
      isReservePriceMet: isReservePriceMet,
    });
  };

  const updateUserProfileWithBid = async (auctionId) => {
    const userRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const myBids = userDoc.data().myBids || [];

      const auctionIndex = myBids.indexOf(auctionId);
      if (auctionIndex === -1) {
        // Auction ID not found, push it to the array
        await updateDoc(userRef, { myBids: [...myBids, auctionId] });
      }
    }
  };
  // Outbids function
  const addOutBidAuctionToUser = async (userId, auctionId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      // Check if the user already has an outbids array
      const outBids = userData?.outBids || [];
      // Add the auction ID to the user's outbids array if it's not already there
      if (!outBids.includes(auctionId)) {
        outBids.push(auctionId);
        await updateDoc(userDocRef, { outBids });
      }
    } catch (error) {}
  };

  const autoBidForUsers = async (
    bidHistory,
    highestBid,
    currentHighestBidderId
  ) => {
    const usersAutoBidded = new Set(); // Keep track of users who have been auto-bidded
    const usersHighestOptionalBid = new Map(); // Keep track of users and their highest optional bids

    // Filter out users whose optional bid amount is higher than the highest bid
    const eligibleBids = bidHistory.filter(
      (bid) =>
        bid.optionalBidAmount > highestBid &&
        bid.userId !== currentHighestBidderId
    );

    // Group bids by user and find their highest optional bid
    eligibleBids.forEach((bid) => {
      // Check if the user already exists in the map
      if (!usersHighestOptionalBid.has(bid.userId)) {
        // If not, create a new entry with user information
        usersHighestOptionalBid.set(bid.userId, {
          optionalBidAmount: bid.optionalBidAmount,
          nationality: bid.nationality,
          username: bid.username,
          userEmail: bid.userEmail,
        });
      } else {
        // If user exists, update the entry only if the new bid is higher
        const currentBid = usersHighestOptionalBid.get(bid.userId);
        if (bid.optionalBidAmount > currentBid.optionalBidAmount) {
          usersHighestOptionalBid.set(bid.userId, {
            optionalBidAmount: bid.optionalBidAmount,
            nationality: bid.nationality,
            username: bid.username,
            userEmail: bid.userEmail,
          });
        }
      }
    });

    // Sort eligible users by their highest optional bid amount
    const sortedUsers = [...usersHighestOptionalBid].sort(
      ([, a], [, b]) => a.optionalBidAmount - b.optionalBidAmount
    );

    // Loop through eligible users and auto-bid for each user
    for (const [userId, userDetails] of sortedUsers) {
      if (!usersAutoBidded.has(userId)) {
        try {
          // Call the function to process the auto bid for the user
          const userBidAmountForAutoBidWithFee = addFeeToUserAutoBid(
            userDetails.optionalBidAmount
          );
          await processBids(
            userBidAmountForAutoBidWithFee,
            userId,
            userDetails.userEmail,
            userDetails.nationality,
            userDetails.username,
            true,
            userDetails.optionalBidAmount
          );
          usersAutoBidded.add(userId); // Add user to the set of auto-bidded users
        } catch (error) {
          console.error(`Error auto-bidding for user ${userId}:`, error);
        }
      }
    }
  };

  // add fee to user auto bid
  const addFeeToUserAutoBid = (number) => {
    const bidRanges = [
      { min: 0, max: 99, fee: 5 },
      { min: 100, max: 499, fee: 10 },
      { min: 500, max: 999, fee: 25 },
      { min: 1000, max: 4999, fee: 50 },
      { min: 5000, max: 9999, fee: 100 },
      { min: 10000, max: 49999, fee: 250 },
      { min: 50000, max: 99999, fee: 500 },
      { min: 100000, max: Infinity, fee: 1000 },
    ];

    const range = bidRanges.find(
      (range) => number >= range.min && number <= range.max
    );

    if (!range) {
      return number;
    }

    return parseInt(number) + parseInt(range.fee);
  };

  // Submit bid data to backend
  const submitHandler = async (formData) => {
    // bid-amount-optional
    try {
      setShowLoader(true);
      // Check if bid-amount is present and submit it
      // if (formData.hasOwnProperty('bid-amount')) {
      //   await processBids(formData['bid-amount']);
      //   }
      if (
        formData.hasOwnProperty("bid-amount") &&
        formData.hasOwnProperty("bid-amount-optional")
      ) {
        await processBids(
          formData["bid-amount"],
          user.uid,
          user.email,
          userData.nationality,
          userData.nickname,
          false,
          formData["bid-amount-optional"]
        );
      } else {
        await processBids(
          formData["bid-amount"],
          user.uid,
          user.email,
          userData.nationality,
          false,
          userData.nickname
        );
      }
    } catch (error) {
      console.error("Error ", error);
      // toast.error("Error");
    }
  };

  const generateQuickBidValues = (startingBid) => {
    const bidRanges = [
      { min: 0, max: 99, fee: 5 },
      { min: 100, max: 499, fee: 10 },
      { min: 500, max: 999, fee: 25 },
      { min: 1000, max: 4999, fee: 50 },
      { min: 5000, max: 9999, fee: 100 },
      { min: 10000, max: 49999, fee: 250 },
      { min: 50000, max: 99999, fee: 500 },
      { min: 100000, max: Infinity, fee: 1000 },
    ];

    const currentRange = bidRanges.find(
      (range) => startingBid >= range.min && startingBid <= range.max
    );

    if (!currentRange) {
      // Handle the case where startingBid is not within any defined bid range
      console.error("Starting bid is outside of defined bid ranges");
      return [];
    }

    const increment = currentRange.fee; // Increment based on the initial fee

    // Calculate the next fees based on the formula (startingBid + increment * (index + 1))
    const nextFees = Array.from(
      { length: 3 },
      (_, index) => startingBid + increment * (index + 1)
    );

    return nextFees;
  };

  // const startingBid = parseInt(
  //   auctionItem?.highestBid > 0 ? auctionItem?.highestBid : 10,
  //   10
  // );

  let startingBid = 10;
  if (auctionItem?.highestBid) {
    startingBid = parseInt(auctionItem.highestBid, 10);
  } else if (auctionItem?.auctionStartingPrice) {
    startingBid = parseInt(auctionItem.auctionStartingPrice, 10);
  }

  const quickBids = generateQuickBidValues(startingBid);

  const likeAuction = async (userId, auctionId, email) => {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const likedAuctions = userDoc.data().likedAuctions || [];
      const auctionIndex = likedAuctions.indexOf(auctionId);

      if (auctionIndex === -1) {
        likedAuctions.push(auctionId);
        await updateDoc(userRef, { likedAuctions });
        toast.success("Auction Liked");
        setUserLiked(true);
      } else {
        likedAuctions.splice(auctionIndex, 1);
        await updateDoc(userRef, { likedAuctions });
        setUserLiked(false);
        toast.success("Auction Disliked");
      }

      // Update likedBy array in the auction document
      const auctionDocRef = doc(db, "Auction", auctionId);
      const auctionDoc = await getDoc(auctionDocRef);

      if (auctionDoc.exists()) {
        const auctionData = auctionDoc.data();
        let likedBy = auctionData.likedBy || [];
        if (auctionIndex === -1) {
          // Add user's email and UID to likedBy array
          likedBy.push({ uid: userId, email });
        } else {
          // Remove user's email and UID from likedBy array
          likedBy = likedBy.filter((user) => user.uid !== userId);
        }
        await updateDoc(auctionDocRef, { likedBy });
      }
    }
  };

  const checkUserLiked = async (userId, auctionId) => {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const likedAuctions = userDoc.data().likedAuctions || [];
      if (likedAuctions.includes(auctionId)) {
        setUserLiked(true);
      }
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user && auctionItem) {
      checkUserLiked(user.uid, auctionItem?.id);
    }
  }, [auctionItem]);

  const updateTimeRemaining = () => {
    if (
      auctionItem?.serverEndTimeForAuction &&
      auctionItem?.serverStartTimeForAuction
    ) {
      // Get current UTC time
      const now = new Date().getTime();

      // Convert serverStartTime and serverEndTime to local time
      const localStartTime = new Date(
        auctionItem.serverStartTimeForAuction?.toMillis()
      );
      const localEndTime = new Date(
        auctionItem.serverEndTimeForAuction?.toMillis()
      );

      const startDate = localStartTime.getTime();
      const endDate = localEndTime.getTime();

      if (now < startDate) {
        // Auction hasn't started yet
        setAuctionStarted(false);
        setStartDatePassed(false);
        setTimeRemaining(startDate - now);
      } else if (now >= startDate && now <= endDate) {
        // Auction is ongoing
        setAuctionStarted(true);
        setStartDatePassed(true);
        setTimeRemaining(endDate - now);
      } else {
        // Auction has ended
        setAuctionStarted(false);
        setStartDatePassed(true);
        setTimeRemaining(0);
      }
    }
  };

  // Initial timer setup
  useEffect(() => {
    updateTimeRemaining();
  }, [auctionItem]);

  // Timer logic
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTimeRemaining();
    }, 1000);

    // Cleanup interval
    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   if (
  //     auctionItem?.serverEndTimeForAuction &&
  //     auctionItem?.serverStartTimeForAuction
  //   ) {
  //     // Get current UTC time
  //     const now = new Date().getTime();

  //     // Convert serverStartTime and serverEndTime to local time
  //     const localStartTime = new Date(
  //       auctionItem.serverStartTimeForAuction?.toMillis()
  //     );
  //     const localEndTime = new Date(
  //       auctionItem.serverEndTimeForAuction?.toMillis()
  //     );

  //     const startDate = localStartTime.getTime();
  //     const endDate = localEndTime.getTime();

  //     if (now < startDate) {
  //       // Auction hasn't started yet
  //       setAuctionStarted(false);
  //       setStartDatePassed(false);
  //       setTimeRemaining(startDate - now);
  //       clearTimeout(timerRef.current);
  //     } else if (now >= startDate && now <= endDate) {
  //       // Auction is ongoing
  //       setAuctionStarted(true);
  //       setStartDatePassed(true);
  //       setTimeRemaining(endDate - now);
  //     } else {
  //       // Auction has ended
  //       setAuctionStarted(false);
  //       setStartDatePassed(true);
  //       setTimeRemaining(0);
  //     }
  //   }

  // // Check if the auction end time has been extended
  // const intervalId = setInterval(() => {
  //   if (auctionItem?.serverEndTimeForAuction) {
  //     const now = new Date().getTime();
  //     const localEndTime = new Date(
  //       auctionItem.serverEndTimeForAuction?.toMillis()
  //     );
  //     const endDate = localEndTime.getTime();

  //     if (now <= endDate) {
  //       setTimeRemaining(endDate - now);
  //     } else {
  //       setTimeRemaining(0);
  //       clearInterval(intervalId);
  //     }
  //   }
  // }, 1000);

  // return () => {
  // clearTimeout(timerRef.current);
  // clearInterval(intervalId);
  //   };
  // }, [auctionItem]);

  const fetchAuctionItem = (id, setAuctionItem, setLoading) => {
    const auctionRef = doc(db, "Auction", id);

    const unsubscribe = onSnapshot(auctionRef, (auctionDoc) => {
      if (
        auctionDoc.exists() &&
        auctionDoc.data().status !== "pending" &&
        auctionDoc.data().status !== "suspended" &&
        // auctionDoc.data().status !== "sold" &&
        auctionDoc.data().status !== "trashed"
      ) {
        setAuctionItem({ id: auctionDoc.id, ...auctionDoc.data() });
      } else {
        setAuctionItem(null);
      }
      setLoading(false);
    });

    // Return an unsubscribe function to stop listening when needed
    return unsubscribe;
  };

  useEffect(() => {
    const unsub = fetchAuctionItem(id, setAuctionItem, setLoading);
    return () => unsub();
  }, [id]);

  // Create a URL for Payment and navigate to it
  const payNowHandler = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://stripe-server-production-3c2c.up.railway.app/generate-payment-url",
        {
          auctionId: auctionItem.id,
          userId: userData.uid,
          userCommission: userData.commissionPercent,
        }
      );
      setLoading(false);
      if (data.url) {
        navigate(data.url);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process payment right now please try again later");
    }
  };

  const userHighestBid = auctionItem?.bidHistory
    ?.filter((bid) => bid.userId === user?.uid)
    .reduce(
      (maxBid, currentBid) =>
        parseInt(currentBid.bidAmount, auctionItem.auctionStarted) >
        parseInt(maxBid.bidAmount, auctionItem.auctionStarted)
          ? currentBid
          : maxBid,
      { bidAmount: "0" }
    );

  const shouldRenderBid = userHighestBid && userHighestBid.bidAmount !== "0";

  if (loading) {
    return <Loading />;
  }

  if (!auctionItem) {
    return (
      <div className="item-error">Auction not found or error fetching data</div>
    );
  }

  const containerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const currentLanguage = i18n.language;

  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        // ref={scrollIntoView}
      >
        <div className="auctionItem-details-page">
          <motion.button
            variants={childVariants}
            whileHover={{ scale: 1.1 }}
            // className="back-btn"
            onClick={() => {
              // navigate(-1);
              if (user) {
                navigate("/profile");
              } else {
                navigate("/");
              }
            }}
            className="back-btn"
            // onClick={() => {
            //   navigate(-1);
            // }}
          >
            <img src={leftArrow} alt="" />
          </motion.button>
          {/* {timeRemaining <= 0 ? (
          <h2 className="ended-auc">Auction Ended</h2>
        ) : (
          <Countdown
            endDate={auctionItem.endDate}
            endTime={auctionItem.endTime}
          />
        )} */}
          <motion.h2 variants={childVariants} className="aidp-head">
            {/* {auctionItem?.auctionTitle}#{auctionItem.tag} */}
            {userLanguage === "it" || currentLanguage === "it" ? (
              <>
                {auctionItem.auctionTitleItalian}{" "}
                <sub style={{ fontSize: "15px", marginLeft: "20px" }}>
                  ({auctionItem.tag})
                </sub>
              </>
            ) : (
              <>
                {auctionItem.auctionTitle}{" "}
                <sub style={{ fontSize: "15px", marginLeft: "20px" }}>
                  ({auctionItem.tag})
                </sub>
              </>
            )}
          </motion.h2>
          <div>
            <div className="auctionItem-details-page-container">
              <motion.div
                className="auctionItem-details-page-container-slider"
                variants={childVariants}
              >
                {/* <ImageSlider images={auctionItem?.images} /> */}
                <Carousel images={auctionItem.images} />
              </motion.div>
              <motion.div variants={childVariants} className="w-50">
                {timeRemaining > 0 && auctionItem?.isTimeExtended && (
                  <div className="time-extended-warning">
                    {/* <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      color="orange"
                    /> */}
                    <span>TIME EXTENDED 4 MINS</span>
                  </div>
                )}
                {timeRemaining <= 0 ? (
                  <div className="ended-con">
                    <h2 className="ended-auc">{t("auctionEnded")}</h2>
                    {user && (
                      <button onClick={() => setShowBidModal(true)}>
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          color="#e63946"
                        />
                        {/* {auctionItem?.bidHistory?.length} {t("bids")} */}
                      </button>
                    )}
                  </div>
                ) : auctionStarted ? (
                  // Auction is ongoing - show countdown for end date
                  <Countdown
                    serverTime={auctionItem.serverEndTimeForAuction.toMillis()}
                    // endDate={auctionItem.endDate}
                    // endTime={auctionItem.endTime}
                  />
                ) : (
                  // Auction hasn't started yet - show countdown for start date
                  <div>
                    <h3 style={{ textAlign: "center", width: "100%" }}>
                      {t("auctionStartsIn")}
                    </h3>
                    <Countdown
                      serverTime={auctionItem.serverStartTimeForAuction.toMillis()}
                      // endDate={auctionItem.startDate}
                      // endTime={auctionItem.startTime}
                    />
                  </div>
                )}
                <div className="auctionItem-details-page-bid-details">
                  {auctionStarted ? (
                    <>
                      <div className="current-bidder">
                        <div>
                          {auctionItem?.highestBid > 0 ? (
                            <div className="no_of_bids">
                              <h3>
                                <span className="lst-bid">{t("lastBid")}</span>{" "}
                                <span className="text-overflow-hidden">
                                  <img
                                    src={euro}
                                    alt=""
                                    width={"17px"}
                                    height={"17px"}
                                  />
                                  {/* <FontAwesomeIcon icon={faEuro} /> */}
                                  {auctionItem?.highestBid}
                                </span>
                              </h3>
                              {user && (
                                <button onClick={() => setShowBidModal(true)}>
                                  {auctionItem?.bidHistory?.length} {t("bids")}
                                </button>
                              )}
                            </div>
                          ) : (
                            <h3 className="no-bid-h3">{t("noBids")}</h3>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                {/* <div className="min-bid">
                  {auctionItem?.highestBid > 0 ? (
                    <div className="my-and-min-bid">
                      <div className="min-bid-span">
                        <span className="bid-t ">{t("minBid")}</span>
                        <span className="bid-p text-overflow-hidden">
                          <img
                            src={euro}
                            alt=""
                            width={"30px"}
                            height={"30px"}
                          />
                          {quickBids[0]}
                        </span>
                      </div>
                      {shouldRenderBid && (
                        <div className="min-bid-span">
                          <span className="bid-t">My Bid</span>
                          <span className="bid-p text-overflow-hidden">
                            <img
                              src={euro}
                              alt=""
                              width={"30px"}
                              height={"30px"}
                            />{" "}
                            {userHighestBid.bidAmount}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="min-bid-span">
                      <span className="bid-t">{t("minBid")}</span>
                      <span className="bid-p">
                        <img src={euro} alt="" width={"30px"} height={"30px"} />
                        {auctionItem.auctionStartingPrice}
                      </span>
                    </div>
                  )}
                </div> */}

                <div className="quickBid">
                  {timeRemaining <= 0 ? null : auctionStarted ? (
                    <>
                      <h4>{t("quickBids")}</h4>
                      <ul className="ul">
                        {quickBids.map((bid, index) => (
                          <li onClick={() => setBidAmount(bid)} key={index}>
                            {/* <FontAwesomeIcon icon={faEuro} /> */}
                            <img
                              src={euro}
                              alt=""
                              width={"15px"}
                              height={"15px"}
                            />
                            <span className="text-overflow-hidden">{bid}</span>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : null}
                  {timeRemaining <= 0 ? null : auctionStarted ? (
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="input-fields">
                        <div className={`input-field-containers`}>
                          <img src={iconEuro} alt="icon" width={30} />
                          <input
                            // style={{ paddingLeft: "45px" }}
                            type={"number"}
                            name={"quickBidValue"}
                            id={id}
                            placeholder={"1000"}
                            autoComplete="off"
                            min={0}
                            value={bidAmount}
                            onChange={(e) => setBidAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </form>
                  ) : null}

                  {/* <span
                  style={{
                    // marginTop: "3rem",
                    color:
                      auctionItem?.highestBid > auctionItem.auctionReservePrice
                        ? "green"
                        : "red",
                  }}
                >
                  {auctionItem?.highestBid > auctionItem.auctionReservePrice
                    ? "Reserve Price Met"
                    : "Reserve Price Not Met"}
                </span> */}
                  <ReservePrice auction={auctionItem} />
                  {user ? (
                    <div className="btn-container">
                      {timeRemaining <= 0 ? null : auctionStarted ? (
                        user.uid === auctionItem.uid ? null : (
                          <Button
                            className="auctionItem-details-page-btn"
                            onClick={() => {
                              checkUserFieldsCompleted(
                                userData,
                                setShowCompleteInfoModal,
                                setBidNow
                                // setUserProfileMissingFields
                              );
                              // setBidNow(true);
                            }}
                          >
                            {showBidNow || showCompleteInfoModal ? (
                              <div
                                className="spinner_containers"
                                aria-label="Loading..."
                              >
                                <i className="spinner_item"></i>
                                <i className="spinner_item"></i>
                              </div>
                            ) : (
                              t("bidNow")
                            )}
                          </Button>
                        )
                      ) : null}

                      {userLiked ? (
                        <AuthBtn
                          onClick={() => {
                            const user = auth.currentUser;
                            if (user) {
                              likeAuction(user.uid, auctionItem.id, user.email);
                            }
                          }}
                          className="auctionItem-details-page-follow-btn"
                        >
                          <FontAwesomeIcon icon={faHeartCircleCheck} color="" />
                          <p>{t("liked")}</p>
                        </AuthBtn>
                      ) : (
                        <AuthBtn
                          onClick={() => {
                            const user = auth.currentUser;
                            if (user) {
                              likeAuction(user.uid, auctionItem.id, user.email);
                            }
                          }}
                          className="auctionItem-details-page-follow-btn"
                        >
                          <FontAwesomeIcon icon={faHeart} color="" />
                          <p>{t("like")}</p>
                        </AuthBtn>
                      )}
                      <AuthBtn
                        onClick={() => setReportModal(true)}
                        className="auctionItem-details-report-btn"
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <p>{t("report")}</p>
                      </AuthBtn>

                      {timeRemaining <= 0 &&
                        (auctionItem.status === "pending_payment" &&
                        auctionItem?.highestBid &&
                        auctionItem?.highestBid >
                          auctionItem?.auctionReservePrice &&
                        auctionItem?.bidHistory &&
                        auctionItem?.lastBidderId === userData?.uid ? (
                          <Button
                            onClick={payNowHandler}
                            className="auctionItem-details-page-btn"
                            style={{ marginTop: "10px" }}
                          >
                            PAY NOW
                          </Button>
                        ) : null)}

                      {timeRemaining <= 0 &&
                        (auctionItem?.highestBid <
                          auctionItem.auctionReservePrice ||
                          !auctionItem.bidHistory) && (
                          <Button
                            style={{ marginTop: ".5rem" }}
                            onClick={() => setContactModal(true)}
                            className="auctionItem-details-page-btn"
                          >
                            <p>Contact us</p>
                          </Button>
                        )}
                    </div>
                  ) : (
                    <div className="btn-container">
                      <Button
                        className="auctionItem-details-page-btn"
                        onClick={showLoginModalHandler}
                      >
                        Login
                      </Button>
                    </div>
                  )}
                </div>
              </motion.div>
            </div>
            <motion.div variants={childVariants} className="div-of-description">
              {userLanguage === "it" || currentLanguage === "it" ? (
                <div className="description-content ql-editor">
                  <h3 className="h3-des">{t("description")}</h3>
                  {/* <p>{auctionItem.descriptionItalian}</p> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: auctionItem.descriptionItalian,
                    }}
                  />
                </div>
              ) : (
                <div className="description-content ql-editor">
                  <h3 className="h3-des ">{t("description")}</h3>
                  {/* <p>{auctionItem.descriptionEnglish}</p> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: auctionItem.descriptionEnglish,
                    }}
                  />
                </div>
              )}
              <TagsColors tags={auctionItem.tags} />
            </motion.div>
            <motion.div variants={childVariants}></motion.div>
          </div>
        </div>

        <AnimatePresence>
          {" "}
          {showBidNow && (
            <BidNowModal
              onClose={() => setBidNow(false)}
              auctionItem={auctionItem}
              // highestBidAmount={highestBidAmount}
              bidAmount={bidAmount}
              showLoader={showLoader}
              handleSubmit={submitHandler}
              optionalBidAmount={optionalBidAmount}
              quickBids={quickBids}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {" "}
          {showBidsModal && (
            <>
              <Overlay onClose={() => setShowBidModal(false)} />
              <BidsModal
                bids={auctionItem.bidHistory}
                onClose={() => setShowBidModal(false)}
              />
            </>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showReportModal && (
            <>
              <Overlay onClose={() => setReportModal(false)} />
              <Modal
                onCancel={() => setReportModal(false)}
                onAgree={() => {
                  setReportModal(false);
                  setShowBidReportModal(true);
                }}
                cancelText={"Cancel"}
                successText={"Continue"}
                icon={faExclamationTriangle}
              >
                {/* <h2>Are you sure you want to report {auctionItem.auctionTitle}</h2> */}
                <h2>Report</h2>
                <p>
                  Are you sure you want to report
                  <span style={{ fontWeight: "bold" }}>
                    {`"${auctionItem.auctionTitle}"`}
                  </span>
                  auction
                </p>
              </Modal>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showContactModal && (
            <>
              <Overlay onClose={() => setContactModal(false)} />
              <Modal
                onCancel={() => setContactModal(false)}
                onAgree={() => {
                  setContactModal(false);
                  setShowBidContactModal(true);
                }}
                cancelText={"Cancel"}
                successText={"Continue"}
                icon={faExclamationTriangle}
              >
                {/* <h2>Are you sure you want to report {auctionItem.auctionTitle}</h2> */}
                <h2>Contact</h2>
                <p>
                  Are you sure you want to contact us for
                  <span style={{ fontWeight: "bold" }}>
                    {`"${auctionItem.auctionTitle}"`}{" "}
                  </span>
                  auction
                </p>
              </Modal>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showCompleteInfoModal && (
            <>
              <Overlay onClose={() => setShowCompleteInfoModal(false)} />
              <Modal
                onCancel={() => setShowCompleteInfoModal(false)}
                onAgree={() => {
                  setShowCompleteInfoModal(false);
                  navigate("/profile");
                  // setShowBidReportModal(true);
                }}
                cancelText={"Cancel"}
                successText={"Profile"}
                icon={faExclamationTriangle}
              >
                {/* <h2>Are you sure you want to report {auctionItem.auctionTitle}</h2> */}
                <h2>Complete Profile</h2>
                <p>
                  Please complete your personal info in your profile. Such as
                  Payment Method, Address, First Name, Last Name and Nickname
                </p>
              </Modal>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showBidReportModal && (
            <>
              <Overlay
                onClose={() => {
                  setShowBidReportModal(false);
                }}
              />
              <ReportModal>
                <ReportAuctionForm
                  auction={auctionItem}
                  user={userData}
                  setModal={setShowBidReportModal}
                />
              </ReportModal>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showBidContactModal && (
            <>
              <Overlay
                onClose={() => {
                  setShowBidContactModal(false);
                }}
              />
              <ReportModal>
                <EditAuctionRequestForm
                  auction={auctionItem}
                  auctionId={auctionItem.id}
                  setModal={setShowBidContactModal}
                  contactUs={true}
                  collectionName={"contactUsMessages"}
                />
              </ReportModal>
            </>
          )}
        </AnimatePresence>
      </motion.div>
      <h2 className="similarItem">Similar Item</h2>
      <SimilarItems filter={auctionItem.category} />
    </>
  );
};

export default AuctionItemDetailPage;

const checkUserFieldsCompleted = async (
  user,
  setShowCompleteInfoModal,
  setBidNow
  // setUserProfileMissingFields
) => {
  if (hasCompletedInformation(user)) {
    // setShowCompleteInfoModal(true);
    // console.log(hasCompletedInformation(user));
    setBidNow(true);
  } else {
    setShowCompleteInfoModal(true);
    // setUserProfileMissingFields(getMissingFields(user));
  }
};

// Function to send notification for first-time bid
const sendFirstTimeBidNotification = async (
  user,
  metaData,
  auctionImageTag
) => {
  const link = `https://join.bidandgo.it/product/en/${metaData.auctionTitleEn}/${metaData.auctionId}`;
  try {
    // Send notification to the user
    fetchAndSendEmailTemplate(
      "For First Bid Of User",
      user,
      "https://stripe-server-production-3c2c.up.railway.app/send-email",
      link,
      metaData,
      auctionImageTag
    );
  } catch (error) {
    return;
  }
};
// Function to send notification for Out bid bid
const sendOutbidNotification = async (user, metaData, auctionImageTag) => {
  const link = `https://join.bidandgo.it/product/en/${metaData.auctionTitleEn}/${metaData.auctionId}`;
  try {
    // Send notification to the user
    fetchAndSendEmailTemplate(
      "For User Out Bid",
      user,
      "https://stripe-server-production-3c2c.up.railway.app/send-email",
      link,
      metaData,
      auctionImageTag
    );
  } catch (error) {
    return;
  }
};
// Function to send notification about highest bid
const sendHighestBidNotification = async (user, metaData, auctionImageTag) => {
  const link = `https://join.bidandgo.it/product/en/${metaData.auctionTitleEn}/${metaData.auctionId}`;
  try {
    fetchAndSendEmailTemplate(
      "For User Bid",
      user,
      "https://stripe-server-production-3c2c.up.railway.app/send-email",
      link,
      metaData,
      auctionImageTag
    );
  } catch (error) {
    return;
  }
};

// Function to check if the user's bid is the highest bid on the auction item
const checkBidAndSendNotification = async (
  user,
  bidAmount,
  bidHistory,
  lastBidderEmail,
  lastBidderUid,
  metaData,
  auctionImage
) => {
  const auctionImageTag = `<img src='${auctionImage}' alt='Auction Item' width={300} height={300} />`;

  try {
    // Check if the bid history is empty or if it contains only one entry corresponding to the user
    if (
      bidHistory.length === 0 ||
      (bidHistory.length === 1 && bidHistory[0].userEmail === user.email)
    ) {
      // Call function to send first-time bid notification
      sendFirstTimeBidNotification(user, metaData, auctionImageTag);
    }

    // Check if it's the user's first bid
    const userBid = bidHistory.find((bid) => bid.userEmail === user.email);
    // Sort the bid history in descending order of bid amounts
    const sortedBidHistory = [...bidHistory].sort(
      (a, b) => b.bidAmount - a.bidAmount
    );

    const outBid = sortedBidHistory[0].userEmail !== lastBidderEmail;

    //send outbid notification
    if (outBid && lastBidderEmail && lastBidderUid) {
      // Call function to send outbid notification
      const u = { email: lastBidderEmail, uid: lastBidderUid };
      sendOutbidNotification(u, metaData, auctionImageTag);
    }

    // If the user has no previous bids on this auction item, it means they are bidding for the first time
    if (!userBid) {
      // Call function to send first-time bid notification
      sendFirstTimeBidNotification(user, metaData, auctionImageTag);
    } else {
      // Get the highest bid amount
      const highestBidAmount = sortedBidHistory[0].bidAmount;
      // Check if the user's bid amount is equal to the highest bid amount
      if (bidAmount === highestBidAmount) {
        // Call function to send notification about highest bid
        sendHighestBidNotification(user, metaData, auctionImageTag);
      }
    }
  } catch (error) {
    console.error("Error checking bid and sending notification:", error);
  }
};

const SimilarItems = ({ filter }) => {
  const { state, getAllAuctions } = useContext(AuctionContext);
  useEffect(() => {
    getAllAuctions();
  }, []);

  return (
    <div>
      <Slider
        auctions={state.allAuctions}
        hideHeading={true}
        filterFor={filter}
      />
    </div>
  );
};
