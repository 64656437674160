import { useState } from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import Button from "../Button";

const PaymentForm = ({
  user,
  paymentSubmitHandler,
  setShowLoader,
  setShowCardForm,
}) => {
  const [paymentError, setPaymentError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [cardErrors, setCardErrors] = useState({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });

  const handleCardChange = (event) => {
    const { elementType, error } = event;

    setCardErrors((prev) => ({
      ...prev,
      [elementType]: error ? error.message : null,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe or Element is not loaded yet");
      return;
    }

    try {
      setShowLoader(true);
      const { data } = await axios.post(
        "https://stripe-server-production-3c2c.up.railway.app/create-setup-intent",
        {
          customerId: user.customerId,
        }
      );
      if (!data.clientSecret) {
        setShowLoader(false);
        return;
      }

      try {
        const { setupIntent, error } = await stripe.confirmCardSetup(
          data.clientSecret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          }
        );

        if (error) {
          setPaymentError(error.message);
          setShowLoader(false);
          return;
        }
        if (setupIntent && setupIntent.id) {
          paymentSubmitHandler();
        }
      } catch (error) {
        setPaymentError("Error processing payment");
        setShowLoader(false);
      }
    } catch (error) {
      setPaymentError("Error no customer found");
      setShowLoader(false);
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className="stripeForm">
      {/* <CardElement options={{ style: { base: inputStyle } }} /> */}
      <div className="card-div">
        <label>Card Number</label>
        <div className="card-input">
          <CardNumberElement id="card-number" onChange={handleCardChange} />
        </div>
        {cardErrors.cardNumber && (
          <div className="cardErrors">{cardErrors.cardNumber}</div>
        )}
      </div>
      <div className="card-div">
        <label>CVC</label>
        <div className="card-input">
          <CardCvcElement id="card-cvc" onChange={handleCardChange} />
        </div>
        {cardErrors.cardCvc && (
          <div className="cardErrors">{cardErrors.cardCvc}</div>
        )}
      </div>
      <div className="card-div">
        <label>Expiry</label>
        <div className="card-input">
          <CardExpiryElement id="card-expiry" onChange={handleCardChange} />
        </div>
        {cardErrors.cardExpiry && (
          <div className="cardErrors">{cardErrors.cardExpiry}</div>
        )}
      </div>
      {paymentError && <div className="card-error">{paymentError}</div>}
      <Button onClick={handleSubmit} className={"paymentBtn"}>
        Add Payment
      </Button>
      <Button className={"card-btn"} onClick={() => setShowCardForm(false)}>
        Cancel
      </Button>
    </form>
  );
};

export default PaymentForm;
