import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import lockIcon from "../assets/icons/lock.svg";
import emailIcon from "../assets/icons/email.svg";
import userIcon from "../assets/icons/user.svg";
import facebookIcon from "../assets/icons/facebook.svg";
import googleIcon from "../assets/icons/google.svg";
import amazonIcon from "../assets/icons/amazon.svg";
import cancelIcon from "../assets/icons/cancel.svg";
import AuthBtn from "../components/AuthBtn";
import Button from "../components/Button";
import InputField from "../components/InputField";
import "../styles/sinupStyles.css";
import { firstNames, lastNames } from "../utils/names";
import {
  agreeTosAndPpValidation,
  emailValidation,
  passwordValidation,
  usernameValidation,
} from "../utils/inputValidations";
import {
  auth,
  db,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../config/firebaseSetup";
import { useUser } from "../context/UserContext";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import Loading from "../components/Loader";
import { toast } from "react-toastify";
import DropdownSelect from "../components/DropdownSelect";
import { countries } from "../utils/countries";
import { useTranslation } from "react-i18next";
import axios from "axios";
import fetchAndSendEmailTemplate from "../utils/emailSend";

const SignUpPage = () => {
  const { showLoginModalHandler, closeAuthModalHandler } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [nationality, setNationality] = useState(countries[0]);
  // console.log(nationality);
  const methods = useForm({
    defaultValues: {
      "agree-tos-pp": true,
    },
  });
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const { login, logout } = useUser();

  // const generateName = () => {
  //   const newName = `${
  //     firstNames[Math.floor(Math.random() * firstNames.length)]
  //   } ${lastNames[Math.floor(Math.random() * lastNames.length)]}`;
  //   methods.setValue("username", newName);
  //   return newName;
  // };

  // useEffect(() => {
  //   generateName();
  // }, []);

  // const checkLocalStorageUser = () => {
  //   const storedUser = localStorage.getItem("loginUser");
  //   if (storedUser) {
  //     console.log("user in localStorage is :", storedUser);
  //     const parsedUser = storedUser;
  //     login(parsedUser);
  //     navigate("/");
  //   }
  // };

  const verifyEmail = async (email) => {
    const { data } = await axios.get(
      `https://emailverifier.reoon.com/api/v1/verify?email=${email}&key=yeSCAHYyz1CBIXtkyJYhAIiHg8uomAFl&mode=power`
    );
    // console.log(data);
    if (data.status === "safe") {
      return true;
    }
    return false;
  };

  const submitHandler = methods.handleSubmit(async (formData) => {
    setShowLoader(true);
    try {
      // Check for nickname if nickname is available
      const nicknameRef = collection(db, "users");
      const nicknameQuery = query(
        nicknameRef,
        where("nickname", "==", formData)
      );

      const querySnapshot = await getDocs(nicknameQuery);

      if (!querySnapshot.empty) {
        setShowLoader(false);
        toast.error(
          "Nickname is already taken, please choose a different one."
        );
        return;
      }

      const isValid = await verifyEmail(formData.email);
      if (!isValid) {
        toast.error("Not a real email please try a new one");
        setShowLoader(false);
        return;
      }

      const res = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = res.user;
      const { data } = await axios.post(
        "https://stripe-server-production-3c2c.up.railway.app/create-customer",
        {
          email: user.email,
          name: formData.username,
        }
      );

      // console.log(nationality);
      // Set the user document with the user's UID as the document ID
      const notificationSettings = {
        loginNotifications: true,
        auctionAcceptNotifications: true,
        auctionRequestSubmitNotifications: true,
        tagRequestSubmitNotifications: true,
        auctionRequestRejectedNotifications: true,
        auctionWonNotifications: true,
        creditAddedNotifications: true,
        creditDeletedNotifications: true,
        paymentSuccessNotifications: true,
      };

      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        nickname: formData.nickname,
        realName: "",
        authProvider: "local",
        email: formData.email,
        isAdmin: false,
        nationality: nationality,
        isASeller: false,
        status: "normal",
        customerId: data.customer.id,
        paymentMethodAdded: false,
        emailNotifications: true,
        commissionPercent: 5,
        notificationSettings: notificationSettings,
        tosAndPpAgreed: formData["agree-tos-pp"],
      });

      localStorage.setItem(
        "loginUser",
        JSON.stringify({ ...formData, nationality })
      );
      toast.success("Account Created Successfully");
      setShowLoader(false);
      closeAuthModalHandler();

      fetchAndSendEmailTemplate(
        "For Sign Up",
        user,
        "https://stripe-server-production-3c2c.up.railway.app/send-email"
      );

      navigate("/profile");

      // if (location.pathname.startsWith("/auction-item")) {
      //   navigate(0);
      // }
    } catch (err) {
      switch (err.code) {
        case "auth/email-already-in-use":
          toast.error("Email is already in use");
          break;
        case "auth/invalid-email":
          toast.error("Invalid email address");
          break;
        case "auth/weak-password":
          toast.error("Weak password. Choose a stronger one.");
          break;
        case "auth/user-not-found":
          toast.error("No user found with this email");
          break;
        case "auth/wrong-password":
          toast.error("Invalid password");
          break;
        case "auth/network-request-failed":
          toast.error("Network request failed. Please try again");
          break;
        default:
          toast.error("Error In Registration");
          console.log(err);
          auth.signOut();
          logout();
      }
      setShowLoader(false);
    }
  });

  const loginWithFacebook = () => {
    console.log("loginWithFacebook");
  };

  const loginWithGoogle = async () => {
    // const username = generateName();
    // const { getValues, setError } = methods;
    // const agreedToTerms = getValues("agree-tos-pp");

    // if (!agreedToTerms) {
    //   setError("agree-tos-pp", {
    //     type: "manual",
    //     message: "You must agree to the Terms of Service and Privacy Policy.",
    //   });
    //   return;
    // }

    setShowLoader(true);
    await signInWithGoogle(closeAuthModalHandler, navigate);
    setShowLoader(false);
    if (location.pathname.startsWith("/auction-item")) {
      navigate(0);
    }
  };

  const loginWithAmazon = () => {
    console.log("loginwithAmazon");
  };

  // useEffect(() => {
  //   checkLocalStorageUser();
  // }, []);

  const divEl = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener("click", handler, true);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const { t } = useTranslation("text");

  return (
    <>
      <h2 className="auth-heading">BIDANDGO</h2>
      <div className="signup-page">
        <div className="signup-page-header-container">
          <div className="signup-page-head">
            <h2>{t("createAcc")}</h2>
            <p>
              {t("createAccDes")}
              <button onClick={showLoginModalHandler}>LOG IN</button>
            </p>
          </div>
          <div onClick={closeAuthModalHandler}>
            <img src={cancelIcon} alt="" />
          </div>
        </div>
        <FormProvider {...methods}>
          <form
            action=""
            onSubmit={(e) => e.preventDefault()}
            autoComplete="off"
          >
            <InputField
              type={"text"}
              placeholder={"Nickname"}
              name={"nickname"}
              id={"nickname"}
              icon={userIcon}
              validationRules={usernameValidation}
              label={"Nickname"}
            />
            {/* <button onClick={generateName} className="generate-name">
             Generate Name
           </button> */}
            <InputField
              type={"email"}
              placeholder={"Email"}
              name={"email"}
              id={"email"}
              icon={emailIcon}
              validationRules={emailValidation}
              label={"Email"}
            />
            <InputField
              type={"password"}
              placeholder={"Create Password"}
              name={"password"}
              id={"password"}
              label={"Password"}
              validationRules={passwordValidation}
              icon={lockIcon}
            />
            <div className="card-form">
              {/* <label htmlFor="cardCategory">{t("Select Nationality")}</label> */}
              <label htmlFor="nationality">{t("Select Nationality")}</label>
              <div className="pcf">
                <select
                  className="month-input"
                  style={{ width: "100%" }}
                  id="nationality"
                  // onChange={handleCategoryChange}
                  name="nationality"
                  {...methods.register("nationality", {
                    required: "nationality is required",
                    onChange: (e) => {
                      const selectedCountry = countries.find(
                        (country) => country.label === e.target.value
                      );

                      if (selectedCountry) {
                        console.log(selectedCountry.id);
                        setNationality({
                          label: e.target.value,
                          id: selectedCountry.id,
                        });
                      }
                    },
                    // value: subCategory,
                  })}
                >
                  <option disabled value="nationality">
                    Nationality
                  </option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.label}>
                      {/* <span
                       style={{ fontSize: "30px" }}
                       className={`fi fi-${country.id.toLowerCase()} fis`}
                     ></span> */}
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="agree-tos-pp-container">
              <div>
                <input
                  {...methods.register("agree-tos-pp", agreeTosAndPpValidation)}
                  type="checkbox"
                  name="agree-tos-pp"
                  id="agree-tos-pp"
                />
                <label
                  htmlFor="agree-tos-pp"
                  className={` ${
                    methods?.formState?.errors["agree-tos-pp"]?.message &&
                    "input-field-error"
                  }`}
                >
                  I confirm that I have read and understood BidandGo.it terms
                  and condition{" "}
                  <button
                    className="agree-link"
                    onClick={() => {
                      navigate("/term-of-service");
                      closeAuthModalHandler();
                    }}
                  >
                    Terms of Service
                  </button>
                  and{" "}
                  <button
                    className="agree-link"
                    onClick={() => {
                      navigate("/privacy-policy");
                      closeAuthModalHandler();
                    }}
                  >
                    Privacy Policy
                  </button>
                </label>
              </div>
              <p className="input-field-error">
                {methods?.formState?.errors["agree-tos-pp"]?.message}
              </p>
            </div>
            <Button className="signup-page-btn" onClick={submitHandler}>
              {t("create")}
            </Button>

            <div className="signup-page-divider">
              <div className="div1"></div>
              <div>or</div>
              <div className="div2"></div>
            </div>
          </form>
        </FormProvider>

        <div className="auth-btns-container">
          <AuthBtn
            className={"login-page-auth-btn-f login-page-auth-btn"}
            onClick={loginWithFacebook}
          >
            <img src={facebookIcon} alt="icon" />
            {/* <p style={{ marginBottom: 0 }}>{t("facebook")}</p> */}
          </AuthBtn>
          <AuthBtn
            className={"login-page-auth-btn login-page-auth-btn-g "}
            onClick={loginWithGoogle}
          >
            <img src={googleIcon} alt="icon" />
            {/* <p style={{ marginBottom: 0 }}>{t("google")}</p> */}
          </AuthBtn>
          <AuthBtn
            className={"login-page-auth-btn login-page-auth-btn-a"}
            onClick={loginWithAmazon}
          >
            <img src={amazonIcon} alt="icon" />
            {/* <p style={{ marginBottom: 0 }}>{t("amazon")}</p> */}
          </AuthBtn>
        </div>
        {showLoader && <Loading />}
      </div>
    </>
  );
};

export default SignUpPage;
