import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { getStorage } from "firebase/storage";
import { toast } from "react-toastify";
import { getFunctions } from "firebase/functions";
import axios from "axios";
import fetchAndSendEmailTemplate from "../utils/emailSend";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBg3nh6dphlyfUUxT1ACGLA4qdWiUrEdIo",
  authDomain: "auction-99162.firebaseapp.com",
  projectId: "auction-99162",
  storageBucket: "auction-99162.appspot.com",
  messagingSenderId: "331264878211",
  appId: "1:331264878211:web:bb58753b48e2382d931db6",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const googleProvider = new GoogleAuthProvider();


const signInWithGoogle = async (closeModal, navigate) => {
  try {
    // Sign in with Google popup
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    // Query Firestore to check if the user document exists
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    localStorage.setItem("loginUser", JSON.stringify(user));

    const querySnapshot = await getDocs(q);
    let userData = null;
    // Check if the user document exists
    if (querySnapshot.size > 0) {
      userData = querySnapshot.docs[ 0 ].data();
    }

    // If the user document doesn't exist
    if (docs.docs.length === 0) {
      const userDocRef = doc(db, "users", user.uid); // Create a reference to the user document using the user's UID

      // Call an external API to create a customer with the provided email and name
      const { data } = await axios.post("https://stripe-server-production-3c2c.up.railway.app/create-customer", {
        email: user.email,
      });

      const notificationSettings = {
        loginNotifications: true,
        auctionAcceptNotifications: true,
        auctionRequestSubmitNotifications: true,
        tagRequestSubmitNotifications: true,
        auctionRequestRejectedNotifications: true,
        auctionWonNotifications: true,
        creditAddedNotifications: true,
        creditDeletedNotifications: true,
        paymentSuccessNotifications: true,
      };

      await setDoc(userDocRef, {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        realName: "",
        isASeller: false,
        isAdmin: false,
        status: 'normal',
        customerId: data.customer.id,
        paymentMethodAdded: false,
        emailNotifications: true,
        commissionPercent: 5,
        notificationSettings: notificationSettings,
      });
      // navigate('/profile');
    }

    closeModal();
    toast.success('Login Success');
    if (userData && userData.notificationSettings.loginNotifications) {
      fetchAndSendEmailTemplate(
        "For Login",
        user,
        "https://stripe-server-production-3c2c.up.railway.app/send-email"
      ); // Send email template
    }

  } catch (err) {
    toast.error("Error Logging In"); // Display error message
    console.log(err); // Log the error
  }
};

const mySignInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (err) {
    console.error(err);
    throw err; // Re-throw the error to handle it in the Login component
  }
};

const registerWithEmailAndPassword = async (username, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    console.log("register sucessfully Done");
    // alert("Registraion sucessfully");
    // navigate("/activation");

    const user = res.user;
    console.log("create user in firestore :=>", user);
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      username: username,
      authProvider: "local",
      email: email,
      isAdmin: "false",
    });
    // localStorage.setItem("loginUser", JSON.stringify(user));
  } catch (err) {
    console.error("error in registration", err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  app,
  storage,
  auth,
  db,
  functions,
  signInWithGoogle,
  mySignInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
